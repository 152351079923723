/** Code generated by gen-figma-tokens.mjs; DO NOT EDIT.
 *
 * Figma references:
 * - https://www.figma.com/file/APdwTkap3aMkNL7YiBlDjM/?node-id=0%3A3,* - https://www.figma.com/file/APdwTkap3aMkNL7YiBlDjM/?node-id=709%3A632
 *
 */

// エディターで補完されない場合は `yarn g:theme-typings` を実行した後以下のimport先にジャンプしてください
import type { ThemeTypings } from "@chakra-ui/styled-system/dist/declarations/src/theming.types"

const generatedTextStyles = {
  /** 12-default-bold
   */
  "12-default-bold": {
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "bold",
  },

  /** Body/Medium/12pt
   */
  "body-medium-12pt": {
    fontSize: "12px",
    lineHeight: "21.599998474121094px",
    fontWeight: "medium",
    letterSpacing: "0.05em",
  },

  /** Body/Medium/13pt
   */
  "body-medium-13pt": {
    fontSize: "13px",
    lineHeight: "26px",
    fontWeight: "medium",
    letterSpacing: "0.05em",
  },

  /** Body/Medium/14pt
   */
  "body-medium-14pt": {
    fontSize: "14px",
    lineHeight: "25.19999885559082px",
    fontWeight: "medium",
    letterSpacing: "0.05em",
  },

  /** Body/Medium/14pt-wide
   */
  "body-medium-14pt-wide": {
    fontSize: "14px",
    lineHeight: "42px",
    fontWeight: "medium",
  },

  /** Body/Regular/xxxs
   */
  "body-regular-xxxs": {
    fontSize: "10px",
    lineHeight: "16px",
    fontWeight: "normal",
    letterSpacing: "0.05em",
  },

  /** EN-text/l
   */
  "en-text-l": {
    fontSize: "18px",
    lineHeight: "19.80000114440918px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** EN-text/m
   */
  "en-text-m": {
    fontSize: "16px",
    lineHeight: "17.600000381469727px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** EN-text/s
   */
  "en-text-s": {
    fontSize: "15px",
    lineHeight: "16.5px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** EN-text/xl
   */
  "en-text-xl": {
    fontSize: "20px",
    lineHeight: "22px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** EN-text/xs
   */
  "en-text-xs": {
    fontSize: "11px",
    lineHeight: "12.100000381469727px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** EN-text/xxl
   */
  "en-text-xxl": {
    fontSize: "22px",
    lineHeight: "24.200000762939453px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** EN-text/xxs
   */
  "en-text-xxs": {
    fontSize: "10px",
    lineHeight: "11px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** EN-text/xxxl
   */
  "en-text-xxxl": {
    fontSize: "26px",
    lineHeight: "28.600000381469727px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** EN-Title/Bold/28pt
   */
  "en-title-bold-28pt": {
    fontSize: "28px",
    lineHeight: "29.42799949645996px",
    fontWeight: "bold",
  },

  /** EN-Title/Bold/54pt
   */
  "en-title-bold-54pt": {
    fontSize: "54px",
    lineHeight: "59.400001525878906px",
    fontWeight: "bold",
  },

  /** EN-Title/Bold/60pt
   */
  "en-title-bold-60pt": {
    fontSize: "60px",
    lineHeight: "63.060001373291016px",
    fontWeight: "bold",
  },

  /** EN-Title/Bold/62pt
   */
  "en-title-bold-62pt": {
    fontSize: "65px",
    lineHeight: "68.31500244140625px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** EN-Title/Bold/80pt
   */
  "en-title-bold-80pt": {
    fontSize: "80px",
    lineHeight: "84.08000183105469px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },

  /** text/l
   */
  "text-l": {
    fontSize: "16px",
    lineHeight: "22.399999618530273px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** text/m
   */
  "text-m": {
    fontSize: "14px",
    lineHeight: "19.600000381469727px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** text/s
   */
  "text-s": {
    fontSize: "12px",
    lineHeight: "16.799999237060547px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** text/xl
   */
  "text-xl": {
    fontSize: "18px",
    lineHeight: "25.19999885559082px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** text/xs
   */
  "text-xs": {
    fontSize: "11px",
    lineHeight: "15.399999618530273px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** text/xxl
   */
  "text-xxl": {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** text/xxs
   */
  "text-xxs": {
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** text/xxxl
   */
  "text-xxxl": {
    fontSize: "24px",
    lineHeight: "33.599998474121094px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** Title/Bold/13pt
   */
  "title-bold-13pt": {
    fontSize: "13px",
    lineHeight: "24px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** Title/Bold/14pt
   */
  "title-bold-14pt": {
    fontSize: "14px",
    lineHeight: "32px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** Title/Bold/15pt-wide
   */
  "title-bold-15pt-wide": {
    fontSize: "15px",
    lineHeight: "62px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** Title/Bold/20pt
   */
  "title-bold-20pt": {
    fontSize: "20px",
    lineHeight: "34px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** Title/Bold/23pt
   */
  "title-bold-23pt": {
    fontSize: "23px",
    lineHeight: "32px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** Title/Bold/25pt
   */
  "title-bold-25pt": {
    fontSize: "25px",
    lineHeight: "44px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** Title/Bold/34pt
   */
  "title-bold-34pt": {
    fontSize: "34px",
    lineHeight: "44.19999694824219px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
  },

  /** 見出し/Heading-XXL
   */
  "見出し-heading-xxl": {
    fontSize: "36px",
    lineHeight: "57.60000228881836px",
    fontWeight: "bold",
  },
}
export default generatedTextStyles
