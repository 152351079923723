import { Global } from "@emotion/react"

const FontFaces = () => (
  <Global
    styles={`
      @font-face{
        font-family:"Marion W01 Bold";
        font-weight: 700;
        font-display: block;
        src:url("/assets/fonts/deabcee6-98c8-4cec-aa61-c9291c01cc31.woff2") format("woff2"),url("/assets/fonts/b44c04f8-f171-4f72-9b42-727da1b4d34e.woff") format("woff");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
  `}
  />
)

export default FontFaces
