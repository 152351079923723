import Script from "next/script"
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID
const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH
const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW

export const TagManagerHead =
  GTM_ID && GTM_AUTH && GTM_PREVIEW
    ? () => (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
      )
    : null

export const TagManagerScript =
  GTM_ID && GTM_AUTH && GTM_PREVIEW
    ? (props: { id: string }) => (
        <Script
          id={props.id}
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i,ia,ip){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth='+ia+'&gtm_preview='+ip+'&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}', '${GTM_AUTH}', '${GTM_PREVIEW}');
      `,
          }}
        />
      )
    : null
