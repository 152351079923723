import { space } from "./space"
export const sizes = {
  ...space,
  half: "50%",
  btn: "140px",
  container: {
    xs: "300px",
    sm: "640px",
    md: "878px",
    lg: "1024px",
    xl: "1200px",
  },
}
