import { chakra, Flex } from "@chakra-ui/react"
import { MoniclePageComponent } from "app-env"
import React from "react"
import { Footer } from "./footer"

/** デフォルトのレイアウトコンポーネント */
const DefaultLayout: React.FC<{}> = (props) => {
  return (
    <Flex direction={"column"} minHeight={"100vh" /** TODO: iOS検証 */}>
      <chakra.div flexGrow={1}>{props.children}</chakra.div>
      <Footer as="footer" />
    </Flex>
  )
}

export const getDefaultLayout: MoniclePageComponent["getLayout"] = (page) => (
  <DefaultLayout>{page}</DefaultLayout>
)
