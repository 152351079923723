import { ButtonProps, CSSObject } from "@chakra-ui/react"
import assert from "assert"

/** ホバーすると黒にアニメーションする良く使用するボタン */
const normalVariant = (props: ButtonProps): CSSObject => {
  const size = props.size ?? "md"
  assert(
    supportedSize.includes(size),
    `size "${size}" is not supported, supported size: ${supportedSize}, please add size to components/theme/Button.ts`
  )
  const sizeProps = sizePropsMap[props.size ?? "md"]

  return {
    border: "thin solid",
    borderColor: "black",
    color: "inherit",
    ...sizeProps,
    transition: "ease .2s",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,

    background: "linear-gradient(to left, transparent 50%, #000 50%)",
    backgroundSize: "200% 50%", // 50%なのは隙間対策
    backgroundPosition: "right bottom",

    _hover: {
      backgroundPosition: "left bottom",
      color: "white",
    },
  }
}
const supportedSize = ["md", "lg"]
const sizePropsMap = {
  md: {
    textStyle: "en-text-s",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "30px",
    height: "34px",
  },
  lg: {
    textStyle: "title-bold-14pt-wide",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "0px",
    height: "65px",
  },
}

export const Button = {
  variants: {
    normal: normalVariant,
  },
}
