import { extendTheme } from "@chakra-ui/react"
import { breakpoints } from "./breakpoints"
import { colors } from "./colors"
import { fonts } from "./fonts"
import { space } from "./space"
import { textStyles } from "./textStyles"
import { sizes } from "./sizes"
import { Button } from "./Button"

const theme = extendTheme({
  config: {
    cssVarPrefix: "m",
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  components: {
    Button: Button,
  },
  styles: {
    global: {
      body: {
        bg: colors["fill-main-gray"],
        overflowY: "scroll",
      },
    },
  },
  layerStyles: {
    box: {
      shadow: "box",
      borderRadius: 15,
      bgColor: "white",
    },
  },
  shadows: {
    box: "0px 4px 11px #D6D6D6",
    text: "0px 0px 10px white",
  },
  sizes,
  fonts,
  space,
  textStyles,
  colors,
  breakpoints,
})

export default theme

type Theme = typeof theme
