export const DESCRIPTION_SUFFIX =
  "株式会社モニクルの採用サイトです。モニクルはグループの統括会社として、グループ全体の経営・プロダクトシステム開発・デザイン・マーケティングを担っています。私たちは「金融の力で、安心を届ける。」をミッションに掲げ、「誰もがお金に関する正しい意思決定」ができる社会の実現に向けて、進み続けます。"

export const BASE_DOMAIN = "recruit.monicle.co.jp"
export const BASE_URL = `https://${BASE_DOMAIN}`
export const CORPORATE_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://monicle.co.jp"
    : "http://localhost:3000"

const ONEMILE_BASE_URL = "https://moniclefinancial.co.jp"

export const ONEMILE_PREVIEW_PAGE_URL = `${ONEMILE_BASE_URL}/api/preview/start`
export const ONEMILE_NEWS_PAGE_URL = `${ONEMILE_BASE_URL}/news`
