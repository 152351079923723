import { CSSObject } from "@chakra-ui/react"
import generated from "./textStyles-figma.gen"

export const textStyles = {
  ...Object.fromEntries(
    Object.entries(generated).map(([key, value]) => {
      const v: CSSObject = { ...value }
      if (key.startsWith("en-")) {
        v.fontFamily = "serif"
      }
      return [key, v]
    })
  ),
}
