import { createIcon } from "@chakra-ui/react"

/** 下向きボーダートライアングル */
const ChevronBottomIcon = createIcon({
  viewBox: "0 0 29 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.116 1.116a1.25 1.25 0 0 1 1.768 0L14.5 12.732 26.116 1.116a1.25 1.25 0 0 1 1.768 1.768l-12.5 12.5a1.25 1.25 0 0 1-1.768 0l-12.5-12.5a1.25 1.25 0 0 1 0-1.768Z"
      fill="currentColor"
    />
  ),
})

/** 矢印(上半分) → */
export const RightVHalfArrow = createIcon({
  viewBox: "0 0 18 9",
  path: (
    <path
      d="M0 7.673h17.423L8.962 1.327"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  ),
  defaultProps: {
    fill: "none",
  },
})

/** 矢印 → */
export const RightVArrow = createIcon({
  viewBox: "0 0 10 8",
  path: (
    <path
      d="M1 3.5a.5.5 0 0 0 0 1v-1Zm8.839.854a.5.5 0 0 0 0-.708L6.657.464a.5.5 0 0 0-.707.708L8.778 4 5.95 6.828a.5.5 0 1 0 .707.708l3.182-3.182ZM1 4.5h8.485v-1H1v1Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  ),
  defaultProps: {
    fill: "none",
  },
})

/** 青色の斜め線 / */
export const BlueSlash = createIcon({
  viewBox: "0 0 25 33",
  path: (
    <path stroke="#344EC4" strokeLinecap="round" d="M23.74.699.751 32.203" />
  ),
})

export const More = createIcon({
  viewBox: "0 0 18 4",
  path: (
    <>
      <circle r="2" transform="matrix(1 0 0 -1 9 2)" fill="currentColor" />
      <circle r="2" transform="matrix(1 0 0 -1 16 2)" fill="currentColor" />
      <circle r="2" transform="matrix(1 0 0 -1 2 2)" fill="currentColor" />
    </>
  ),
})

export const MinusRound = createIcon({
  viewBox: "0 0 18 4",
  path: (
    <>
      {/* <circle cx="16" cy="16" r="16" fill="transparent" /> */}
      <rect width="18" height="4" rx="2" fill="currentColor" />
    </>
  ),
})

export const ArrowIcon = createIcon({
  viewBox: "0 0 11 11",
  path: (
    <path
      d="M0.646447 9.64645C0.451184 9.84171 0.451184 10.1583 0.646447 10.3536C0.841709 10.5488 1.15829 10.5488 1.35355 10.3536L0.646447 9.64645ZM10.5 1C10.5 0.723857 10.2761 0.5 10 0.5L5.5 0.5C5.22386 0.5 5 0.723858 5 1C5 1.27614 5.22386 1.5 5.5 1.5L9.5 1.5L9.5 5.5C9.5 5.77614 9.72386 6 10 6C10.2761 6 10.5 5.77614 10.5 5.5L10.5 1ZM1.35355 10.3536L10.3536 1.35355L9.64645 0.646447L0.646447 9.64645L1.35355 10.3536Z"
      fill="currentColor"
    />
  ),
})

export const PlaceIcon = createIcon({
  viewBox: "0 0 9 13",
  path: (
    <>
      <g clipPath="url(#clip0_3570_15601)">
        <path
          d="M4.20883 0.334961C1.88437 0.334961 0 2.21794 0 4.54086C0 4.72813 0.0340902 4.95565 0.0834516 5.18703C0.227062 5.86004 0.48621 6.50282 0.840378 7.09269C1.66625 8.46839 3.55207 11.579 4.07438 12.4402C4.13702 12.5435 4.28431 12.5434 4.34686 12.44C4.86291 11.5875 6.70878 8.53816 7.49831 7.23276C7.85016 6.65091 8.11918 6.02217 8.2884 5.3635C8.36321 5.07227 8.41751 4.77872 8.41751 4.54086C8.41751 2.21794 6.53329 0.334961 4.20883 0.334961ZM4.18554 6.49989C3.1036 6.49989 2.22635 5.6228 2.22635 4.54071C2.22635 3.45861 3.10344 2.58152 4.18554 2.58152C5.26763 2.58152 6.14472 3.45861 6.14472 4.54071C6.14472 5.6228 5.26763 6.49989 4.18554 6.49989Z"
          fill="#666666"
        />
      </g>
      <defs>
        <clipPath id="clip0_3570_15601">
          <rect
            width="8.41751"
            height="12.33"
            fill="white"
            transform="translate(0 0.334961)"
          />
        </clipPath>
      </defs>
    </>
  ),
})

/**
 * エンジニアLP
 */
/** place pin */
export const PlacePinIcon = createIcon({
  viewBox: "0 0 10.91 15.84",
  path: (
    <>
      <path
        fill="#2f3647"
        d="m5.46,0C2.44,0,0,2.44,0,5.45c0,.24.04.54.11.84.19.87.52,1.71.98,2.47,1.1,1.83,3.64,6.02,4.24,7.01.06.1.2.1.26,0,.59-.98,3.08-5.09,4.13-6.83.46-.75.8-1.57,1.02-2.42.1-.38.17-.76.17-1.07,0-3.01-2.44-5.45-5.45-5.45Zm-.03,7.99c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.14,2.54-2.54,2.54Z"
      />
    </>
  ),
})

/** 下向き矢印 */
export const ArrowBottomIcon = createIcon({
  viewBox: "0 0 24.04 13.44",
  path: (
    <>
      <path
        fill="none"
        stroke="#2f3647"
        strokeWidth="2px"
        d="m23.33.71l-11.31,11.31L.71.71"
      />
    </>
  ),
})

/** 別タブアイコン */
const BlankIcon = createIcon({
  viewBox: "0 0 16 13.71",
  path: (
    <>
      <path
        fill="#666"
        d="m16,.57v4.57c0,.16-.06.29-.17.4-.11.11-.24.17-.4.17s-.29-.06-.4-.17l-1.57-1.57-5.82,5.82c-.06.06-.13.09-.2.09s-.14-.03-.2-.09l-1.02-1.02c-.06-.06-.09-.13-.09-.2s.03-.14.09-.2l5.81-5.82-1.57-1.57c-.11-.11-.17-.25-.17-.4s.06-.29.17-.4c.11-.12.24-.17.4-.17h4.57c.15,0,.29.05.4.17.11.12.17.25.17.4h0Zm-3.43,7.71v2.86c0,.71-.25,1.31-.76,1.81-.5.51-1.11.76-1.81.76H2.57c-.71,0-1.31-.25-1.81-.76-.5-.51-.75-1.11-.75-1.81V3.71c0-.71.25-1.32.75-1.82.5-.51,1.11-.75,1.81-.75h6.28c.09,0,.15.02.21.08.05.06.08.12.08.21v.57c0,.09-.03.15-.08.21-.06.05-.12.08-.21.08H2.57c-.39,0-.73.14-1.01.42-.28.28-.42.62-.42,1.01v7.43c0,.39.14.73.42,1.01.28.28.62.42,1.01.42h7.43c.4,0,.73-.14,1.01-.42s.42-.61.42-1.01v-2.86c0-.09.02-.15.08-.2.05-.06.12-.08.2-.08h.57c.09,0,.15.02.2.08.06.06.08.12.08.2h0Z"
      />
    </>
  ),
})

/**
 * Footer
 */
/** 別タブアイコン */
export const FooterBlankIcon = createIcon({
  viewBox: "0 0 16 15",
  path: (
    <>
      <path
        d="M10.4177 5.99825L5.47904 10.937C5.35382 11.0622 5.20651 11.1248 5.0371 11.1248C4.86768 11.1248 4.72037 11.0622 4.59515 10.937C4.46994 10.8117 4.40733 10.6644 4.40733 10.495C4.40733 10.3256 4.46994 10.1783 4.59515 10.0531L9.53385 5.11437L5.20282 5.11437C5.02605 5.11437 4.88057 5.0536 4.76641 4.93207C4.65224 4.81053 4.59515 4.66138 4.59515 4.4846C4.60252 4.31519 4.66329 4.16972 4.77745 4.04818C4.89162 3.92665 5.03709 3.86588 5.21387 3.86588L11.0475 3.86588C11.1359 3.86588 11.2151 3.88246 11.285 3.9156C11.355 3.94875 11.4195 3.99478 11.4784 4.05371C11.5373 4.11263 11.5834 4.17708 11.6165 4.24706C11.6496 4.31703 11.6662 4.39621 11.6662 4.4846L11.6662 10.3182C11.6662 10.4803 11.6055 10.6221 11.4839 10.7436C11.3624 10.8651 11.2169 10.9296 11.0475 10.937C10.8707 10.937 10.7216 10.8762 10.6 10.7546C10.4785 10.6331 10.4177 10.484 10.4177 10.3072L10.4177 5.99825Z" />
    </>
  ),
})