/** Code generated by gen-figma-tokens.mjs; DO NOT EDIT.
 *
 * Figma references:
 * - https://www.figma.com/file/ycGZkCHlH1KRliSoOS78Y6/?node-id=2%3A2
 *
 */

// エディターで補完されない場合は `yarn g:theme-typings` を実行した後以下のimport先にジャンプしてください
import type { ThemeTypings } from "@chakra-ui/styled-system/dist/declarations/src/theming.types"

const generatedColors = {
  /** 2nd-Blue
   * - グレーとのバランスに配慮して使用します
   * - rgba(52,78,196,1)
   */
  "2nd-blue": "#344ec4",

  /** 2nd-Gray
   * - 小さめの説明文で使用します
   * - rgba(102,102,102,1)
   */
  "2nd-gray": "#666666",

  /** 3rd-Blue
   * - ブレーとのバランスに配慮して使用します
   * - rgba(119,154,228,1)
   */
  "3rd-blue": "#779ae4",

  /** 3rd-Gray
   * - 注釈などで使用します
   * - rgba(128,128,128,1)
   */
  "3rd-gray": "#808080",

  /** 4th-Blue
   * - グレーとのバランスに配慮して使用します
   * - rgba(233,244,255,1)
   */
  "4th-blue": "#e9f4ff",

  /** Fill-4th-Gray
   * - 3rdの後に使用します
   * - rgba(204,204,204,1)
   */
  "fill-4th-gray": "#cccccc",

  /** Fill-Main-Gray
   * - 背景塗り潰しなどで使用します
   * - rgba(242,242,242,1)
   */
  "fill-main-gray": "#f2f2f2",

  /** Line-Main-Gray
   * - 基本の罫線で使用します
   * - rgba(128,128,128,1)
   */
  "line-main-gray": "#808080",

  /** Point-Black
   * - 見出しなどで使用します
   * - rgba(0,0,0,1)
   */
  "point-black": "#000000",

  /** Point-Blue
   * - ポイントとして使用します
   * - rgba(43,59,173,1)
   */
  "point-blue": "#2b3bad",

  /** Text-Main-Black
   * - 本文で使用します
   * - rgba(51,51,51,1)
   */
  "text-main-black": "#333333",

  /** White
   * - 本文で白色を使う時に使用します
   * - rgba(255,255,255,1)
   */
  white: "#ffffff",
}
export default generatedColors
