import { ChakraProvider } from "@chakra-ui/react"
import { MoniclePageComponent } from "app-env"
import { BASE_URL, DESCRIPTION_SUFFIX } from "components/constants"
import { getDefaultLayout } from "components/layouts/DefaultLayout"
import theme from "components/theme"
import FontFaces from "components/theme/FontFaces"
import { TagManagerScript } from "components/tracking/gtm"
import { DefaultSeo, DefaultSeoProps } from "next-seo"
import { AppProps } from "next/app"
import Script from "next/script"
import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import "swiper/css/effect-fade"

function MyApp({ Component, pageProps }: AppProps) {
  const page = Component as MoniclePageComponent
  const getLayout =
    page.getLayout ?? (page.getLayout === null ? noopLayout : getDefaultLayout)
  return (
    <ChakraProvider resetCSS theme={theme}>
      <FontFaces />
      <DefaultSeo {...DEFAULT_SEO} />
      <TypescriptFont />
      {getLayout(<Component {...pageProps} />, pageProps)}

      {TagManagerScript && <TagManagerScript id="gtm" />}
      <Debugger />
    </ChakraProvider>
  )
}

const Debugger = () => (
  <>
    {typeof window === "object" &&
      window.location.search.indexOf("__debug=1") !== -1 && (
        <Script
          strategy="afterInteractive"
          src="https://cdnjs.cloudflare.com/ajax/libs/vConsole/3.9.5/vconsole.min.js"
          onLoad={() => {
            if (
              typeof window === "object" &&
              typeof window["VConsole"] !== "undefined"
            ) {
              const vconsole = new window["VConsole"]()
              vconsole.setSwitchPosition(window.innerWidth, 0)
            }
          }}
        />
      )}
  </>
)

const DEFAULT_SEO: DefaultSeoProps = {
  titleTemplate: "%s | 株式会社モニクル",
  defaultTitle: "株式会社モニクル",
  description: `株式会社${DESCRIPTION_SUFFIX}`,
  additionalMetaTags: [
    { name: "theme-color", content: "#333" },
    {
      name: "viewport",
      content: "width=device-width,initial-scale=1.0,viewport-fit=cover",
    },
  ],
  openGraph: {
    url: BASE_URL,
    images: [
      {
        url: `${BASE_URL}/ogp.jpg`,
        width: 2400,
        height: 1260,
        type: "image/jpeg",
      },
    ],
  },
  additionalLinkTags: [
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicon-16x16.png",
    },
    {
      rel: "alternate",
      type: "application/rss+xml",
      href: "/news/feed.xml",
    },
    {
      rel: "preload",
      as: "font",
      href: "/assets/fonts/deabcee6-98c8-4cec-aa61-c9291c01cc31.woff2",
      type: "font/woff2",
    },
    {
      rel: "preload",
      as: "font",
      href: "/assets/fonts/b44c04f8-f171-4f72-9b42-727da1b4d34e.woff",
      type: "font/woff",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap"
    },
  ],
}

const TypescriptFont = () => (
  <>
    <Script id="adobe-fonts" strategy="beforeInteractive">
      {`
      (function(d) {
        var config = {
          kitId: 'exs1vqr',
          scriptTimeout: 3000,
          async: true
        },
        h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
      })(document);
      `}
    </Script>
  </>
)

const noopLayout = (page: any) => page

export default MyApp
