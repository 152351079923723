import { createBreakpoints } from "@chakra-ui/theme-tools"

/** ブレークポイント定義
 * - e.g. `<Box width={{ base: 10, lg: 20 }} />` SPは10, PCは20
 */
export const breakpoints = createBreakpoints({
  // base: 0-320px 一部の幅の狭い端末から適用

  sm: "321px",

  // SPのデザイン基準サイズより広いもの
  md: "376px",

  // SPデザインの上限サイズより広いもの
  // PCデザインの下限サイズ
  lg: "751px",

  // PCデザインの基準サイズより広いもの
  xl: "1201px",

  // PCデザインの基準サイズより大幅に広いもの
  "2xl": "1441px",
})
