// Headerなどは毎回手でtextStyleを指定するのでheading,body共通
const family =
  '"Noto Sans JP", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

export const fonts = {
  heading: family,
  body: family,
  mono: 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',

  serif: `"Marion W01 Bold","Times New Roman",serif`,
  // 英数字はSerif、日本語はSans
  serifGothicJP: `"Marion W01 Bold","Times New Roman","Noto Sans JP",serif`,
}
