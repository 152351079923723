export const space = {
  px: "1px",
  0: "0px",
  1: "5px",
  2: "10px",
  3: "15px",
  4: "20px",
  5: "25px",
  6: "30px",
  7: "35px",
  8: "40px",
  9: "45px",
  10: "50px",
  12: "60px",
  14: "70px",
  15: "75px",
  16: "80px",
  18: "90px",
  20: "100px",
  24: "120px",
  28: "140px",
  32: "160px",
  36: "180px",
  40: "200px",
  44: "220px",
  48: "240px",
  52: "260px",
  56: "280px",
  60: "300px",
  64: "320px",
  72: "360px",
  80: "400px",
  96: "480px",
}
